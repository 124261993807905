var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { AuthTypes, DualAxes, EmptyImage } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import _ from "lodash";
import numeral from "numeral";
import { a as URL_POT_CHART_YEAR, b as URL_POT } from "./api-endpoints.js";
import { P as PromoUtils } from "./promotion.planning.js";
import { T as TableCellSelector } from "./app.js";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { ref: "content", staticClass: "comment-view", class: _vm.isFetching ? "is-fetching" : "" }, [!_vm.isFetching && _vm.commentList && _vm.commentList.length ? _c("a-list", { attrs: { "data-source": _vm.commentList, "item-layout": "horizontal" }, scopedSlots: _vm._u([{ key: "renderItem", fn: function(item, index) {
    return _c("a-list-item", { staticClass: "custom-content", attrs: { "keys": item.id } }, [_c("a-comment", [_c("span", { staticStyle: { "color": "black" }, attrs: { "slot": "author" }, slot: "author" }, [_vm._v(" " + _vm._s(item.author) + " - "), _c("h3", { staticClass: "h3-custom" }, [_vm._v(" " + _vm._s(item.screenName) + " ")]), item.planName ? _c("h3", { staticClass: "h3-custom" }, [_vm._v(" - " + _vm._s(item.planName) + " ")]) : _vm._e(), _vm.checkCanDelete(item) ? _c("a-icon", { staticClass: "custom-content__close", attrs: { "type": "close" }, on: { "click": function($event) {
      return _vm.onDelete(item.id);
    } } }) : _vm._e()], 1), _c("a-avatar", { style: {
      backgroundColor: _vm.generateColor(item.author),
      verticalAlign: "middle"
    }, attrs: { "slot": "avatar" }, slot: "avatar" }, [_vm._v(" " + _vm._s(item.avatar) + " ")]), _c("div", { attrs: { "slot": "content" }, domProps: { "innerHTML": _vm._s(item.content) }, slot: "content" }), _c("span", [_vm._v(_vm._s(item.datetime))])], 1)], 1);
  } }], null, false, 1908499713) }) : _vm._e(), _vm.isFetching ? _c("a-icon", { attrs: { "type": "loading" } }) : !_vm.commentList || _vm.commentList.length == 0 ? _c("div", [_c("div", { staticClass: "d-flex flex-column align-items-center" }, [_c("a-icon", { style: { fontSize: "44px" }, attrs: { "type": "inbox" } }), _c("p", { staticClass: "ant-empty-description" }, [_vm._v("No Data")])], 1)]) : _vm._e()], 1);
};
var staticRenderFns$2 = [];
var CommentView_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$2 = {
  props: {
    commentList: {
      type: Array,
      default: null
    },
    pagination: {
      type: Object,
      default: null
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentHeight: 0
    };
  },
  computed: {
    userInfo() {
      const { namespace, AUTH_GET_USER } = AuthTypes;
      return this.$store.getters[`${namespace}/${AUTH_GET_USER}`];
    }
  },
  watch: {
    commentList(newVal, oldVal) {
      this.$nextTick(() => {
        if (newVal.length > oldVal.length) {
          this.$refs.content.scrollTop = Math.abs(this.currentHeight - this.$refs.content.scrollHeight);
          this.currentHeight = +this.$refs.content.scrollHeight;
        } else {
          this.$refs.content.scrollTop = this.currentHeight = this.$refs.content.scrollHeight;
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.content.scrollTop = this.currentHeight = this.$refs.content.scrollHeight;
      this.$refs.content.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    this.$refs.content.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    generateColor(param) {
      const colorHash = this.stringToHash(param);
      const colorCode = colorHash.substring(0, 6);
      const r = parseInt(colorCode.substring(0, 2), 16);
      const g = parseInt(colorCode.substring(2, 4), 16);
      const b = parseInt(colorCode.substring(4, 6), 16);
      return `rgb(${r}, ${g}, ${b})`;
    },
    stringToHash(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash &= hash;
      }
      return hash.toString(16);
    },
    onDelete(id) {
      this.$emit("onDelete", id);
    },
    handleInfiniteOnLoad() {
      this.$emit("onInfiniteOnLoad");
    },
    loadMoreContent() {
      this.$emit("onLoadMoreContent", (this.pagination.current += 1).toFixed());
    },
    handleScroll() {
      const content = this.$refs.content;
      if (this.pagination.total / this.pagination.pageSize < this.pagination.current)
        return;
      if (content.scrollTop === 0) {
        this.loadMoreContent();
      }
    },
    checkCanDelete(item) {
      if (!this.canDelete) {
        return this.userInfo.userName == item.createdBy;
      } else {
        return !item.isEntryComment;
      }
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "da7e6ace", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var commentView = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("div", [_vm.commentList.length > 0 ? _c("comment-view", { attrs: { "comment-list": _vm.commentList, "pagination": _vm.pagination, "is-fetching": _vm.isFetching, "can-delete": _vm.canDelete }, on: { "onLoadMoreContent": _vm.onLoadMoreContent, "onDelete": _vm.onDelete } }) : _vm._e()], 1), _c("hr"), _vm.canCreate ? _c("div", [_c("div", { staticClass: "pl-4 mt-4", staticStyle: { "position": "relative" } }, [_c("div", { ref: "textarea", staticClass: "w-100 input-area ml-3", attrs: { "contenteditable": "true", "placeholder": "Type comment here" }, on: { "input": _vm.onInput, "keydown": _vm.onKeyDown } }), _vm.showSuggestions ? _c("div", { ref: "suggestions", staticClass: "suggestions p-2", style: { top: _vm.caretY + "px", left: _vm.caretX + "px" } }, [_vm._v(" Suggestions "), _c("hr"), _vm._l(_vm.mentionsList, function(suggestion, index) {
    return _c("div", { key: suggestion.value, staticClass: "suggestions__items my-2", class: { "active-suggestion": index === _vm.activeSuggestionIndex }, on: { "click": function($event) {
      return _vm.onSuggestionClick(suggestion);
    } } }, [_vm._v(" " + _vm._s(suggestion.text) + " ")]);
  })], 2) : _vm._e()]), _vm._t("action", [_c("div", { staticClass: "d-flex justify-content-end" }, [_c("button", { staticClass: "mt-4", on: { "click": _vm.submitComment } }, [_vm._v("Submit Comment")])])], { "onClick": _vm.submitComment })], 2) : _vm._e()]);
};
var staticRenderFns$1 = [];
var CommentInput_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  components: {
    commentView
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    rows: {
      type: Number,
      default: 3
    },
    mentionSuggestions: {
      type: Array,
      default: null
    },
    propCommentList: {
      type: Array,
      default: null
    },
    mentionPlacement: {
      type: String,
      default: "top"
    },
    isPosting: {
      type: Boolean,
      default: false
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    canCreate: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      mentionsSelected: [],
      showSuggestions: false,
      caretX: 0,
      caretY: 0,
      activeSuggestionIndex: 0,
      keyword: ""
    };
  },
  computed: {
    mentionsList() {
      return this.mentionSuggestions;
    },
    commentList() {
      return this.propCommentList;
    }
  },
  watch: {
    isPosting(val) {
      !val && (this.$refs.textarea.innerText = "");
    }
  },
  mounted() {
    window.addEventListener("mousedown", this.onMouseDown);
  },
  beforeDestroy() {
    window.removeEventListener("mousedown", this.onMouseDown);
  },
  methods: {
    submitComment() {
      this.$emit("onInput");
    },
    getMentionSuggestions(value) {
      this.activeSuggestionIndex = 0;
      this.$emit("onSearchMentions", value);
    },
    onMouseDown(e) {
      if (!e.target.className.includes("suggestions"))
        this.showSuggestions = false;
    },
    onSuggestionClick(suggestion) {
      if (!this.mentionsSelected.find((mention) => mention.value == suggestion.value)) {
        this.mentionsSelected.push(suggestion);
      }
      const innerHtml = this.$refs.textarea.innerHTML;
      const textContent = `&nbsp;<span style="color: blue;">${suggestion.text}</span>&nbsp;`;
      this.$refs.textarea.innerHTML = innerHtml.replace(this.keyword, textContent);
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(this.$refs.textarea);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      this.showSuggestions = false;
      this.activeSuggestionIndex = 0;
      this.$emit("onChange", this.$refs.textarea.innerHTML, this.mentionsSelected);
    },
    onLoadMoreContent(page) {
      this.$emit("onLoadMoreContent", page);
    },
    onDelete(id) {
      this.$emit("onDelete", id);
    },
    onInput() {
      const text = this.$refs.textarea.innerText;
      this.mentionsSelected = this.mentionsSelected.filter((mention) => text.includes(mention.text));
      this.keyword = this.getKeyword();
      if (this.keyword && this.showSuggestions) {
        this.$nextTick(() => {
          this.updateCaretPosition();
          this.getMentionSuggestions(this.keyword.slice(1));
        });
      } else
        this.showSuggestions = false;
      this.$emit("onChange", this.$refs.textarea.innerHTML, this.mentionsSelected);
    },
    onKeyDown(event) {
      switch (event.key) {
        case "@":
          this.showSuggestions = true;
          break;
        case "ArrowDown":
          if (this.activeSuggestionIndex == this.mentionsList.length - 1)
            return;
          this.activeSuggestionIndex = this.activeSuggestionIndex + 1;
          event.preventDefault();
          break;
        case "ArrowUp":
          if (this.activeSuggestionIndex == 0)
            return;
          this.activeSuggestionIndex = this.activeSuggestionIndex - 1;
          event.preventDefault();
          break;
        case "Backspace":
          const selection = window.getSelection();
          if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const parentElement = range.commonAncestorContainer.parentElement;
            if (parentElement && parentElement.tagName === "SPAN") {
              parentElement.remove();
              const mention = parentElement.innerText.slice(1);
              const index = this.mentionsSelected.findIndex((m) => m.text == mention);
              if (index !== -1) {
                this.mentionsSelected.splice(index, 1);
              }
              event.preventDefault();
            }
          }
          break;
        case "Enter":
          if (!this.mentionsList.length) {
            this.showSuggestions = false;
            break;
          }
          if (!this.showSuggestions || !this.mentionsList[this.activeSuggestionIndex])
            break;
          this.onSuggestionClick(this.mentionsList[this.activeSuggestionIndex]);
          if (event.shiftKey && !this.showSuggestions) {
            this.submitComment();
          }
          event.preventDefault();
          break;
        case "Escape":
        case " ":
          if (this.showSuggestions)
            this.showSuggestions = false;
          break;
      }
    },
    updateCaretPosition() {
      const text = this.$refs.textarea;
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const tempEl = document.createElement("span");
      range.insertNode(tempEl);
      const { left, height } = tempEl.getBoundingClientRect();
      this.caretY = -(tempEl.offsetHeight + height - text.offsetHeight + 100);
      this.caretX = tempEl.offsetWidth + left - 280;
      tempEl.parentNode.removeChild(tempEl);
    },
    getKeyword() {
      if (window.getSelection && window.getSelection().getRangeAt) {
        let selectedObj = window.getSelection();
        let textContent = selectedObj.anchorNode.textContent;
        if (textContent.includes("@")) {
          let rangeCount = 0;
          let range = window.getSelection().getRangeAt(0);
          let childNodes = selectedObj.anchorNode.parentNode.childNodes;
          for (let i = 0; i < childNodes.length; i++) {
            if (childNodes[i] == selectedObj.anchorNode) {
              break;
            }
            if (childNodes[i].outerHTML)
              rangeCount += childNodes[i].outerHTML.length;
            else if (childNodes[i].nodeType == 3) {
              rangeCount += childNodes[i].textContent.length;
            }
          }
          let startWordIndex = range.startOffset + rangeCount;
          while (textContent[startWordIndex] !== "@") {
            --startWordIndex;
          }
          return textContent.substring(startWordIndex, range.startOffset + rangeCount).split(" ")[0].trim();
        }
      }
      return null;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "73710439", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CommentInput = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "chart-aggregate mx-5 mb-4" }, [_c("div", [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("a-card", [_c("div", { staticClass: "d-flex align-items-center justify-content-between" }, [_c("h1", { staticClass: "neutral-10--text mb-0" }, [_vm._v("IRi")]), _vm._t("extra")], 2), _c("a-row", { staticClass: "my-1", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { attrs: { "data-source": _vm.listYear, "label": "Data Source", "placeholder": "Data Source", "source": "code", "value": _vm.selectFilter.year }, on: { "change": function($event) {
    return _vm.filterChart($event, "year");
  } } })], 1)], 1), _c("a-row", { staticClass: "my-1", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("a-spin", { attrs: { "spinning": _vm.isLoading, "size": "small" } }, [_vm.chartData.length > 0 ? _c("dual-axes", { attrs: { "name": "IRi", "config": _vm.config } }) : _vm._e(), !_vm.showTable && !_vm.chartData.length ? _c("a-empty", { attrs: { "image": _vm.EmptyImage.PRESENTED_IMAGE_SIMPLE } }) : _vm._e()], 1)], 1)], 1), _c("a-row", { directives: [{ name: "show", rawName: "v-show", value: _vm.showTable, expression: "showTable" }], staticClass: "chart-aggregate__table mt-1 mb-3", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("a-dropdown", { attrs: { "trigger": ["contextmenu"] } }, [_c("a-row", { attrs: { "type": "flex" } }, [_vm.selectFilter.year ? _c("a-col", { attrs: { "span": 2 } }, [_c("div", { staticClass: "chart-aggregate__table-label-year d-flex justify-content-center align-items-center text-center" }, [_c("span", { staticClass: "h7" }, [_vm._v(_vm._s(_vm.selectFilter.year))])]), _c("div", { staticClass: "chart-aggregate__table-label-total h7 text-center" }, [_vm._v(" Total ")])]) : _vm._e(), _c("a-col", { attrs: { "span": 22 } }, [_c("div", { ref: "ChartTable" }, [_c("a-table", { attrs: { "columns": _vm.columns, "data-source": _vm.chartTable, "loading": _vm.isLoading, "pagination": false, "row-key": function(record) {
    return record.min + record.max + record.totalRSVFY + "";
  }, "bordered": "" }, on: { "change": _vm.onTableChange } })], 1)])], 1), _c("a-menu", { attrs: { "slot": "overlay" }, slot: "overlay" }, [_c("a-menu-item", { key: "copy", on: { "click": _vm.onCopy } }, [_vm._v("Copy")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = [];
var ChartAggregate_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Charts",
  components: { DualAxes },
  mixins: [PromoUtils],
  props: {
    promotionId: {
      type: Number,
      default: void 0
    },
    showTable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chartData: [],
      chartTable: [],
      chartTableDefault: [],
      columns: [],
      EmptyImage,
      isLoading: false,
      isMounted: true,
      listYear: [],
      selectFilter: {
        year: null
      },
      tcs: null,
      tcsBuffer: null
    };
  },
  computed: {
    config() {
      return {
        autoFit: false,
        padding: [16, 16],
        data: [this.chartData, this.chartData],
        xField: "week",
        yField: ["unit", "price"],
        meta: {
          unit: {
            formatter: (val) => {
              return numeral(val).format("0,0");
            }
          }
        },
        legend: {
          offsetY: -5,
          position: "top-right",
          itemName: {
            formatter: (text) => {
              return text.charAt(0).toUpperCase() + text.slice(1);
            }
          }
        },
        yAxis: {
          unit: false,
          price: {
            min: 0,
            label: {
              formatter: () => ` `
            },
            grid: {
              line: {
                style: {
                  lineWidth: 1,
                  lineDash: [2, 2],
                  cursor: "pointer"
                }
              }
            }
          }
        },
        geometryOptions: [
          {
            geometry: "column",
            color: "#17D395",
            label: {
              position: "middle",
              style: {
                fill: "#272D35",
                opacity: 0.6
              }
            }
          },
          {
            geometry: "line",
            color: "#FFA940",
            lineStyle: {
              lineWidth: 2
            },
            label: {
              position: "middle",
              style: {
                fill: "#272D35",
                opacity: 0.6
              }
            },
            point: {
              size: 3,
              style: {
                fill: "white",
                stroke: "#FFA940",
                lineWidth: 2
              }
            }
          }
        ]
      };
    },
    params() {
      return this.$route.params;
    }
  },
  watch: {
    params(val) {
      if (val) {
        this.getChartYear();
      }
    },
    columns(val) {
      if (val) {
        if (this.tcs) {
          this.destroyTcs();
        }
        this.$nextTick(() => {
          this.initTcs();
        });
      }
    },
    listYear(val) {
      if (this.isMounted && val && val.length > 0) {
        this.selectFilter.year = val[val.length - 1].code;
        this.fetchChartData();
        this.isMounted = false;
      }
    }
  },
  mounted() {
    this.getChartYear();
    this.initTcs();
  },
  beforeDestroy() {
    this.destroyTcs();
  },
  methods: {
    getChartYear() {
      var _a;
      const promotionId = (_a = this.promotionId) != null ? _a : this.params.id;
      if (promotionId) {
        const url = apiUrl + URL_POT_CHART_YEAR + `?promotionId=${promotionId}`;
        this.axios.get(url).then((res) => {
          let { data: values } = res;
          this.listYear = values.listYear;
        }).finally(() => this.isLoading = false);
      }
    },
    customColumn(tableData) {
      if (tableData.length) {
        const valueTotalRSVFY = tableData[tableData.length - 1].totalRSVFY;
        const valueUnitFY = tableData[tableData.length - 1].totalUnitFY;
        this.columns = [
          {
            title: "Total RSV: " + this.formatCurrency(valueTotalRSVFY),
            children: [
              {
                title: "Avg PTC",
                children: [
                  {
                    title: "FY",
                    dataIndex: "avgPriceFY",
                    key: "avgPriceFY",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value) : "",
                        attrs: {
                          "data-coord-x": 0,
                          "data-coord-y": index + 3,
                          "data-field": "avgPriceFY",
                          "data-value": value
                        }
                      };
                    }
                  }
                ]
              }
            ]
          },
          {
            title: " ",
            children: [
              {
                title: "Frequency (Weeks)",
                children: [
                  {
                    title: "Q1",
                    dataIndex: "freqWeeksQ1",
                    key: "freqWeeksQ1",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value) : "",
                        attrs: {
                          "data-coord-x": 1,
                          "data-coord-y": index + 3,
                          "data-field": "freqWeeksQ1",
                          "data-value": value
                        }
                      };
                    }
                  },
                  {
                    title: "Q2",
                    dataIndex: "freqWeeksQ2",
                    key: "freqWeeksQ2",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value) : "",
                        attrs: {
                          "data-coord-x": 2,
                          "data-coord-y": index + 3,
                          "data-field": "freqWeeksQ2",
                          "data-value": value
                        }
                      };
                    }
                  },
                  {
                    title: "Q3",
                    dataIndex: "freqWeeksQ3",
                    key: "freqWeeksQ3",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value) : "",
                        attrs: {
                          "data-coord-x": 3,
                          "data-coord-y": index + 3,
                          "data-field": "freqWeeksQ3",
                          "data-value": value
                        }
                      };
                    }
                  },
                  {
                    title: "Q4",
                    dataIndex: "freqWeeksQ4",
                    key: "freqWeeksQ4",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value) : "",
                        attrs: {
                          "data-coord-x": 4,
                          "data-coord-y": index + 3,
                          "data-field": "freqWeeksQ4",
                          "data-value": value
                        }
                      };
                    }
                  },
                  {
                    title: "FY",
                    dataIndex: "freqWeeksFY",
                    key: "freqWeeksFY",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value) : "",
                        attrs: {
                          "data-coord-x": 5,
                          "data-coord-y": index + 3,
                          "data-field": "freqWeeksFY",
                          "data-value": value
                        }
                      };
                    }
                  }
                ]
              }
            ]
          },
          {
            title: `Total Units ${this.formatDecimal(valueUnitFY)}`,
            children: [
              {
                title: "Units/Week",
                children: [
                  {
                    title: "Q1",
                    dataIndex: "unitPerWeekQ1",
                    key: "unitPerWeekQ1",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value, true) : "",
                        attrs: {
                          "data-coord-x": 6,
                          "data-coord-y": index + 3,
                          "data-field": "unitPerWeekQ1",
                          "data-value": value
                        }
                      };
                    }
                  },
                  {
                    title: "Q2",
                    dataIndex: "unitPerWeekQ2",
                    key: "unitPerWeekQ2",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value, true) : "",
                        attrs: {
                          "data-coord-x": 7,
                          "data-coord-y": index + 3,
                          "data-field": "unitPerWeekQ2",
                          "data-value": value
                        }
                      };
                    }
                  },
                  {
                    title: "Q3",
                    dataIndex: "unitPerWeekQ3",
                    key: "unitPerWeekQ3",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value, true) : "",
                        attrs: {
                          "data-coord-x": 8,
                          "data-coord-y": index + 3,
                          "data-field": "unitPerWeekQ3",
                          "data-value": value
                        }
                      };
                    }
                  },
                  {
                    title: "Q4",
                    dataIndex: "unitPerWeekQ4",
                    key: "unitPerWeekQ4",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value, true) : "",
                        attrs: {
                          "data-coord-x": 9,
                          "data-coord-y": index + 3,
                          "data-field": "unitPerWeekQ4",
                          "data-value": value
                        }
                      };
                    }
                  },
                  {
                    title: "FY",
                    dataIndex: "unitPerWeekFY",
                    key: "unitPerWeekFY",
                    sorter: true,
                    customRender: (value, row, index) => {
                      return {
                        children: value ? this.formatDecimal(value, true) : "",
                        attrs: {
                          "data-coord-x": 10,
                          "data-coord-y": index + 3,
                          "data-field": "unitPerWeekFY",
                          "data-value": value
                        }
                      };
                    }
                  }
                ]
              }
            ]
          }
        ];
      }
    },
    filterChart: _.debounce(function(value, key) {
      this.selectFilter = __spreadProps(__spreadValues({}, this.selectFilter), { [key]: value });
      if (typeof value === "number") {
        this.fetchChartData();
      }
    }, 500),
    fetchChartData() {
      var _a;
      const promotionId = (_a = this.promotionId) != null ? _a : this.$route.params.id;
      if (promotionId) {
        this.isLoading = true;
        let url = apiUrl + URL_POT + `/${promotionId}/chart?`;
        let queries = [];
        for (const [key, value] of Object.entries(this.selectFilter)) {
          queries.push(`${key}=${value}`);
        }
        url += queries.join("&");
        this.axios.get(url).then((res) => {
          let { data: values } = res;
          this.chartTable = values.chartTable;
          this.chartTableDefault = values.chartTable;
          this.customColumn(values.chartTable);
          this.chartData = values.chartData.map((x) => ({
            unit: x.unit,
            price: parseFloat(x.price.toFixed(2)),
            week: this.$moment(x.week).format("DD/MM/YYYY")
          }));
        }).finally(() => this.isLoading = false);
      }
    },
    initTcs() {
      const tcsOptions = {
        deselectOutTableClick: true,
        ignoreThead: true,
        ignoreTfoot: true,
        selectIgnoreClass: false,
        getCellFn: (cell) => this.parseDataForCopying(cell)
      };
      const buffer = new TableCellSelector.Buffer();
      this.tcsBuffer = buffer;
      this.tcs = new TableCellSelector(this.$refs.ChartTable.querySelector(".ant-table-body table"), tcsOptions, buffer);
    },
    parseDataForCopying(cell) {
      if (!cell || !cell.dataset)
        return;
      return cell.dataset.value;
    },
    onCopy() {
      this.tcs.copy();
    },
    destroyTcs() {
      this.tcsBuffer.destroy();
      this.tcs.destroy();
    },
    onTableChange(pagination, filters, sorter) {
      let lastArr = this.chartTableDefault[this.chartTableDefault.length - 1];
      switch (sorter.order) {
        case "ascend":
          this.chartTable = this.chartTableDefault.slice(0, this.chartTableDefault.length - 1).sort((a, b) => {
            return a[sorter.field] - b[sorter.field];
          });
          this.chartTable.push(lastArr);
          this.customColumn(this.chartTable);
          break;
        case "descend":
          this.chartTable = this.chartTableDefault.slice(0, this.chartTableDefault.length - 1).sort((a, b) => {
            return b[sorter.field] - a[sorter.field];
          });
          this.chartTable.push(lastArr);
          this.customColumn(this.chartTable);
          break;
        default:
          this.chartTable = this.chartTableDefault;
          this.customColumn(this.chartTable);
          break;
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "d5d77992", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var ChartAggregate = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { CommentInput as C, ChartAggregate as a };
